import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { FormControl, FormHelperText } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

export interface PillOption {
  value: string; // Main option value
  color?: string; // Optional color for the pill button
  subOptions?: string[]; // Optional sub-options for this main option
  icon?: React.ComponentType; // Optional icon for the main option
  subIcon?: React.ComponentType; // Optional icon for the sub-options
}

interface PillsSelectProps {
  label: string; // Label for the select field
  name: string; // Field name for Formik
  options: PillOption[]; // Main options (pill buttons)
  value?: string; // Optional controlled value prop
  onChange?: (value: string | string[]) => void; // Optional onChange prop to notify the parent of the change
  postOnChange?: (value: string | string[], previousValue: string | string[]) => void; // Optional postOnChange that takes 2 arguments
  required?: boolean; // Mark the field as required
  fullWidth?: boolean; // Optional fullWidth flag for FormControl
  containerStyle?: React.CSSProperties; // Optional custom style for the container
  multiple?: boolean; // Whether multiple selections are allowed (only affects the data type of selected value)
}

const PillsSelect: React.FC<PillsSelectProps> = ({
  label,
  name,
  options,
  value: controlledValue,
  onChange: controlledOnChange,
  postOnChange,
  required,
  fullWidth,
  containerStyle,
  multiple = false, // Default to false for single selection
}) => {
  const [selected, setSelected] = useState<string[]>([]); // Internal state for selected value (as array for multiple)
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({}); // Track expansion state for each option
  const [additionalOptions, setAdditionalOptions] = useState<{ [key: string]: string[] }>({}); // Store additional options per parent
  const [field, meta, helpers] = useField(name); // Formik hook to access form state, error, and helper methods

  const { value: formikValue } = field; // Get Formik field value

  // Sync Formik value with internal state on mount or when controlledValue changes
  useEffect(() => {
    // Prevent infinite rerender by only setting selected value if it differs from current
    if(formikValue){
      if (multiple && Array.isArray(formikValue) && !arraysEqual(formikValue, selected)) {
        setSelected(formikValue); // For multiple selections, set the array as internal state
      } else if (!multiple && formikValue !== selected[0]) {
        setSelected(formikValue ? [formikValue] : []); // For single selection, set as string array
      }
    }
  }, [formikValue, controlledValue, selected, multiple]);

  // Helper function to compare arrays (for avoiding infinite rerender)
  const arraysEqual = (a: string[], b: string[]) => {
    return a.length === b.length && a.every((val, index) => val === b[index]);
  };

  // Handle main option selection
  const handleSelect = (option: string) => {
    const previousValue = selected;
    const pill = options.find((pill) => pill.value === option);

    if (pill?.subOptions) {
      // If it's a main option with sub-options, toggle the sub-options
      setExpanded((prev) => {
        const newExpanded = { ...prev };
        if (newExpanded[option]) {
          delete newExpanded[option]; // Collapse if already expanded
        } else {
          // Collapse other expanded options when a new main option is selected
          Object.keys(newExpanded).forEach((key) => delete newExpanded[key]);
          newExpanded[option] = true; // Expand this option
        }
        return newExpanded;
      });
      setAdditionalOptions((prev) => ({
        ...prev,
        [option]: pill.subOptions,
      }));
      return; // Don't set this as the final selected value yet
    }else{
      setExpanded({});
    }

    // If it's a main option without sub-options and multiple is true, store as a one-item array
    if (!pill?.subOptions && multiple) {
      setSelected([option]); // Always store as one-item array for main options, even if multiple is true
      helpers.setValue([option]); // Update Formik value to one-item array
      if (controlledOnChange) controlledOnChange([option]); // Notify parent if onChange is provided
    } else {
      // For single selection, set the selected option
      setSelected([option]); // Update internal state as single selection
      helpers.setValue(option); // Update Formik value as single string
      if (controlledOnChange) controlledOnChange(option); // Notify parent if onChange is provided
    }

    // After the main onChange logic, call postOnChange if available
    if (postOnChange) {
      postOnChange([option], previousValue); // Pass both the new and previous values
    }
  };

  // Handle sub-option selection
  const handleSubOptionSelect = (subOption: string, parentOption: string) => {
    const previousValue = selected;

    // Toggle the sub-option in the selected array if multiple is true
    let newSelected;
    if (multiple) {
      // If it's already selected, deselect it; otherwise, select it
      if (selected.includes(subOption)) {
        newSelected = selected.filter((item) => item !== subOption);
      } else {
        newSelected = [...selected, subOption];
      }
    } else {
      newSelected = [subOption]; // For single selection, just set the sub-option
    }

    setSelected(newSelected); // Update internal state
    helpers.setValue(newSelected); // Update Formik value
    if (controlledOnChange) controlledOnChange(newSelected); // Notify parent if onChange is provided

    // After sub-option selection, call postOnChange if available
    if (postOnChange) {
      postOnChange(newSelected, previousValue); // Pass both the new and previous values
    }
  };

  return (
    <div style={containerStyle}>
      {/* Label Outside the FormControl */}
      <label htmlFor={name} className="block text-white font-medium mb-2">
        {label} {required && <span className="text-red-500">*</span>}
      </label>

      <FormControl
        fullWidth={fullWidth}
        error={!!meta.error}
        variant="outlined"
      >
        <div className="relative mt-2">
          {/* Pills Selection */}
          <div className="flex flex-wrap gap-2 mb-4">
            {options.map((option) => {
              const pillColor = option.color || 'bg-gray-700';
              const isSelected = selected.includes(option.value); // Check if the main option is selected (for multiple)
              
              return (
                <button
                  key={option.value}
                  onClick={(event) => {
                    event.preventDefault()
                    return handleSelect(option.value)
                  }}
                  className={`px-3 py-2 rounded-full border transition-colors ${pillColor} ${
                    isSelected
                      ? 'bg-opacity-50 shadow-2xl text-white'
                      : 'text-white'
                  } flex items-center`}
                  style={{
                    boxShadow: isSelected ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
                  }}
                >
                  {/* Render icon if available */}
                  {option.icon && (
                    <span className="mr-1">
                      {React.createElement(option.icon)}
                    </span>
                  )}
                  {option.value}

                  {/* Show the up/down arrow if there are sub-options */}
                  {option.subOptions && (
                    <span className="ml-1">
                      {expanded[option.value] ? (
                        <ExpandLess className="text-white" />
                      ) : (
                        <ExpandMore className="text-white" />
                      )}
                    </span>
                  )}
                </button>
              );
            })}
          </div>

          {/* Conditionally render additional options if any */}
          {Object.keys(additionalOptions).map((parentOption) => {
            if (expanded[parentOption]) {
              return (
                <div key={parentOption} className="rounded-lg shadow-md mt-2">
                  <div className="flex flex-wrap gap-2">
                    {additionalOptions[parentOption]?.map((subOption) => {
                      const isSubSelected = selected.includes(subOption); // Check if sub-option is selected

                      return (
                        <button
                          key={subOption}
                          onClick={() => handleSubOptionSelect(subOption, parentOption)}
                          className={`px-3 py-2 rounded-full border transition-colors ${
                            isSubSelected
                              ? 'bg-opacity-50 shadow-2xl text-white'
                              : 'text-white'
                          } bg-gray-400 hover:bg-gray-600 flex items-center`}
                          style={{
                            boxShadow: isSubSelected ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
                          }}
                        >
                          {/* Use the correct subIcon from the parent option */}
                          {options
                            .find((opt) => opt.value === parentOption)
                            ?.subIcon && (
                              <span className="mr-1">
                                {React.createElement(options.find((opt) => opt.value === parentOption)?.subIcon)}
                              </span>
                            )}
                          {subOption}
                        </button>
                      );
                    })}
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>

        {/* Show error message */}
        {meta?.error && meta?.touched && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default PillsSelect;
