import Grid from "@material-ui/core/Grid";
import { flow } from "fp-ts/lib/function";
import { allPass, pathOr, startsWith } from "ramda";
import React from "react";
import { Select } from "./fields/Select";
import { TextField } from "./fields/TextField";
import { JobDetailsFormValues } from "./JobDetailsForm";

const types = ["Brake Repair Service", "Service Call", "Warranty Check"];
const serviceCallReasons = [
  "Error with initial booking diagnosis",
  "No repair necessary",
  "Unavailable parts",
  "Cannot perform the necessary repair",
  "Customer refused service after on-site diagnosis",
  "Could not access vehicle",
  "Customer no show or no response",
  "Customer Offered Free Inspection",
  "Wheel Lock Issue(s)",
];

const warrantyCheckTechReasons = [
  "Workmanship",
  "Squeaking/Noisy Brakes",
  "Pulsation/Vibration Concerns",
  "Software or Sensor Malfunction",
  "Recall-Related",
  "Customer Satisfaction",
  "Unusual Driving Conditions",
  "Other",
];

export const JobTypeFormSection: React.FC<{ values: JobDetailsFormValues }> = ({ values }) => {
  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <Select label="Type" name="type" options={types} fullWidth={true} />
      </Grid>
      <Grid item xs={12}>
        {flow(pathOr("", ["type"]), startsWith("Service"))(values) && (
          <Select label="Service Call Reason" name="serviceCallReason" options={serviceCallReasons} fullWidth={true} />
        )}
        {flow(pathOr("", ["type"]), startsWith("Warranty Check"))(values) && (
          <Select
            label="Warranty Check Reason"
            name="warrantyCheckTechReason"
            options={warrantyCheckTechReasons}
            fullWidth={true}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {allPass([
          flow(pathOr("", ["serviceCallReason"]), startsWith("Other")),
          flow(pathOr("", ["type"]), startsWith("Service")),
        ])(values) && (
          <TextField
            label="Additional Service Call Reason Information"
            name={"serviceCallReasonExtraInfo"}
            multiline={true}
            rows={3}
          />
        )}
        {allPass([
          flow(pathOr("", ["warrantyCheckTechReason"]), startsWith("Other")),
          flow(pathOr("", ["type"]), startsWith("Warranty Check")),
        ])(values) && (
          <TextField
            label="Additional Warranty Check Reason Information"
            name={"warrantyCheckTechReasonExtraInfo"}
            multiline={true}
            rows={3}
          />
        )}
      </Grid>
    </Grid>
  );
};
